import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  Checkbox,
  Stack,
} from '@mui/material';
import DoubleScrollbar from 'react-double-scrollbar';
import FilterViewButton from './FilterViewButton';
import { useLocalization } from 'src/context/LocalizationContext';

const hoverColor = '#f5f5f5';

const hoverStyles = {
  '&:hover': {
    backgroundColor: hoverColor,
    cursor: 'pointer',
  },
};

const DynamicTable = ({
  data,
  headers,
  rowsPerPageOptions = [25, 50, 100],
  initialRowsPerPage = 25,
  initialOrder,
  initalOrderDirection,
  isOrdered,
  rowOnClick,
  selected,
  handleCheckboxChange,
  handleModal,
  isMobile,
  children,
  rowStyles,
  resetPage,
}) => {
  const { language } = useLocalization();
  const [columns, setColumns] = useState(headers);
  const [order, setOrder] = useState(initalOrderDirection);
  const [orderBy, setOrderBy] = useState(initialOrder);
  const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);
  const [sortBy, setSortBy] = useState();
  const [page, setPage] = useState(0);

  const handleRequestSort = (property, sortBy) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    if (sortBy) {
      setSortBy(sortBy);
    }
  };

  useEffect(() => {
    if (resetPage) {
      setPage(0);
    }
  }, [resetPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getValue = (obj, path) => {
    const parts = path.match(/([^[.\]])+/g);
    return parts.reduce((o, key) => (o && o[key] ? o[key] : undefined), obj);
  };

  const sortedData = JSON.parse(JSON.stringify(data)).sort((a, b) => {
    const key = orderBy;
    const sortKey = sortBy || key;

    const column = columns.find((col) => col.id === orderBy); // Sıralanan sütunu bul
    if (column) {
      // Sütun bir sortFunction içeriyorsa onu kullan, yoksa getValue ile default değer al
      const valueA = column.sortFunction
        ? column.sortFunction(a)
        : getValue(a, sortKey);
      const valueB = column.sortFunction
        ? column.sortFunction(b)
        : getValue(b, sortKey);

      if (valueA == null && valueB == null) return 0;
      if (valueA == null) return 1;
      if (valueB == null) return -1;

      if (typeof valueA === 'number' && typeof valueB === 'number') {
        return order === 'asc' ? valueA - valueB : valueB - valueA;
      }

      if (typeof valueA === 'string' && typeof valueB === 'string') {
        return order === 'asc'
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      }

      return 0;
    }
    return 0;
  });

  useEffect(() => {
    if (isOrdered) {
      setColumns([
        { id: 'no', label: 'No', alignRight: false, sx: { width: 10 } },
        ...columns,
      ]);
    }
  }, []);

  const getDeviceInfo = () => {
    const userAgent = window.navigator.userAgent;

    const isMobile = /Mobi|Android/i.test(userAgent);
    const isTablet = /Tablet|iPad/i.test(userAgent);
    const isIOS = /iPhone|iPad|iPod/i.test(userAgent);
    const isAndroid = /Android/i.test(userAgent);

    return {
      isDevice: isMobile || isTablet || isIOS || isAndroid,
    };
  };

  const { isDevice } = getDeviceInfo();

  return (
    <>
      <Stack
        flexDirection={'row'}
        justifyContent={handleModal || children ? 'space-between' : 'end'}
        paddingLeft={3}
        paddingRight={3}
        paddingTop={3}
      >
        <Stack direction="row" spacing={2}>
          <>
            {isMobile ? null : handleModal ? (
              <FilterViewButton variant="contained" onClick={handleModal} />
            ) : null}
            {children ? <div>{children}</div> : null}
          </>
        </Stack>

        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={language.components.DynamicTable.text}
        />
      </Stack>

      <DoubleScrollbar>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  sx={column.sx}
                  align={column.align || 'left'}
                  sortDirection={orderBy === column.id ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : 'asc'}
                    onClick={() =>
                      column.sortBy
                        ? handleRequestSort(column.id, column.sortBy)
                        : handleRequestSort(column.id, column.id)
                    }
                  >
                    {column.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <TableRow
                  onClick={
                    rowOnClick && isDevice ? () => rowOnClick(row) : null
                  }
                  style={rowStyles ? rowStyles(row) : {}} // Stil prop'u burada kullanılıyor
                  sx={
                    rowOnClick && isDevice
                      ? {
                          ...hoverStyles,
                          '&:last-child td, &:last-child th': { border: 0 },
                        }
                      : {}
                  }
                  hover
                  key={index}
                  tabIndex={-1}
                >
                  {columns.map((column) => {
                    const value = row[column.id];
                    if (column.id === 'no') {
                      return (
                        <TableCell key="index" align="left">
                          {page * rowsPerPage + index + 1}
                        </TableCell>
                      );
                    } else if (column.id === 'checkbox') {
                      return (
                        <TableCell key="checkbox" align="left">
                          {row.status === '5' && (
                            <Checkbox
                              checked={selected?.indexOf(row.id) !== -1}
                              onChange={(e) =>
                                handleCheckboxChange(e, row.id, row)
                              }
                            />
                          )}
                        </TableCell>
                      );
                    } else {
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align || 'left'}
                        >
                          {column.format ? column.format(value, row) : value}
                        </TableCell>
                      );
                    }
                  })}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </DoubleScrollbar>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={language.components.DynamicTable.text}
      />
    </>
  );
};

export default DynamicTable;
