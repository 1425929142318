import React from 'react';
import { useEffect, useState } from 'react';
import api from 'src/services/BaseService';
import { Helmet } from 'react-helmet-async';
import {
  Card,
  Stack,
  Typography,
  useMediaQuery,
  Select,
  MenuItem,
  Switch,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
  Button,
} from '@mui/material';
import { toast } from 'react-toastify';
import EditButton from 'src/components/shared/EditButton';
import DynamicTable from 'src/components/shared/DynamicTable';
import { useLocalization } from 'src/context/LocalizationContext';

export default function StoreAuthorizations() {
  const { language } = useLocalization();
  const [userList, setUserList] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [mallID, setMallID] = useState('');
  const [branchList, setBranchList] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogType, setDialogType] = useState('confirm');
  const [selectedUser, setSelectedUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const isMobile = useMediaQuery('(max-width:768px)');

  const TABLE_HEAD = [
    {
      id: 'username',
      label: language.commons.labels.username,
      alignRight: false,
    },
    {
      id: 'name',
      label: language.commons.labels.name,
      alignRight: false,
    },
    {
      id: 'lastname',
      label: language.commons.labels.lastname,
      alignRight: false,
    },
    {
      id: 'frontAuth',
      label: language.commons.labels.frontAuth,
      alignRight: false,
      width: 10,
      format: (value, row) => {
        const frontAuthReplacements = {
          operations: 'Operasyon',
          robot: 'Robot',
          manager: 'Yönetici',
          'can-cancel': 'İptal Yetkisi',
        };
        return Array.isArray(value)
          ? value.map((val) => frontAuthReplacements[val] || val).join(', ')
          : '';
      },
    },
    {
      id: 'branchTitle',
      label: language.commons.column.store_name,
      alignRight: false,
    },
    {
      id: 'operation',
      label: language.commons.status,
      alignRight: false,
      format: (value, row) => {
        return (
          <Switch
            checked={row?.frontAuth?.includes('manager')}
            onChange={() => handleSwitchChange(row)}
            name="isAdmin"
            color="primary"
          />
        );
      },
      sx: { width: 100 },
    },
  ];

  const handleSwitchChange = (user) => {
    setSelectedUser(user);
    setDialogType('confirm');
    setDialogOpen(true);
  };

  const handleConfirmAction = async () => {
    if (!selectedUser) return;
    setLoading(true);

    const isManager = selectedUser?.frontAuth?.includes('manager');
    const endpoint = 'users/update-profile';

    try {
      const response = await api.post(endpoint, {
        userID: selectedUser.id,
        isAdministrator: !isManager,
        frontAuth: isManager ? ['operations'] : ['operations', 'manager'],
      });

      if (response.data?.status) {
        toast.success(
          `${selectedUser.name} ${selectedUser.lastname} adlı kullanıcının ${
            isManager ? 'yönetici yetkisi kaldırıldı' : 'yönetici olarak atandı'
          }.`
        );

        handleBranchSelect(selectedBranch?.branchID);

        setDialogType('success');
      } else {
        toast.error('İşlem başarısız oldu.');
        setDialogOpen(false);
      }
    } catch (error) {
      toast.error('Bir hata oluştu.');
      setDialogOpen(false);
    } finally {
      setLoading(false);
    }
  };

  const handleBranchSelect = (branchID) => {
    const selected = branchList.find((branch) => branch.branchID === branchID);
    setSelectedBranch(selected);

    api
      .post('users/get-users', {
        branchID: branchID,
        status: true,
      })
      .then((response) => {
        if (response.data.status) {
          const updatedUsers = response.data.result.map((user) => ({
            ...user,
            branchTitle: selected?.title,
          }));
          setUserList(updatedUsers);
        } else {
          toast.error('Kullanıcı bulunamadı');
          setUserList([]);
        }
      })
      .catch((error) => {
        toast.error(error.message);
        setUserList([]);
      });
  };

  useEffect(() => {
    const mallID = localStorage.getItem('mallID');
    setMallID(mallID);

    api
      .post('flow/get-malls-branches', {
        mallID: mallID,
      })
      .then((response) => {
        if (response.data.status) {
          const formattedBranches = response.data.result.map(
            ({ title, branchID }) => ({
              title,
              branchID,
            })
          );
          setBranchList(formattedBranches);
        } else {
          setBranchList([]);
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        setBranchList([]);
        toast.error(error.message);
      });
  }, []);

  return (
    <>
      <Helmet>
        <title> {language.store_authorizations.titles.site_title} </title>
      </Helmet>

      <Stack style={{ paddingInline: '25px' }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            {language.store_authorizations.titles.store_auth}
          </Typography>
        </Stack>
        <Card>
          <DynamicTable
            data={userList}
            headers={TABLE_HEAD}
            initialOrder={'createdDateTime'}
            initalOrderDirection={'asc'}
            isOrdered={true}
            isMobile={isMobile}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                padding: '10px 0',
              }}
            >
              <Select
                sx={{
                  width: '200px',
                }}
                value={selectedBranch?.branchID || ''}
                onChange={(e) => handleBranchSelect(e.target.value)}
                displayEmpty
              >
                <MenuItem value="" disabled>
                  {language.commons.select_store}
                </MenuItem>
                {branchList.map((branch) => (
                  <MenuItem key={branch.branchID} value={branch.branchID}>
                    {branch.title}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </DynamicTable>
        </Card>
      </Stack>

      {/* Dialog */}
      <Dialog
        open={dialogOpen}
        onClose={() => {
          if (dialogType === 'success') {
            handleBranchSelect(selectedBranch?.branchID);
          }
        }}
        maxWidth="xs"
        fullWidth
        disableBackdropClick
        disableEscapeKeyDown
        PaperProps={{
          style: {
            borderRadius: '12px',
            padding: '20px',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
          },
        }}
      >
        {dialogType === 'confirm' ? (
          <>
            <DialogTitle
              style={{ color: '#BBBBBB', marginLeft: -15, marginTop: -15 }}
            >
              Onay Talebi
              <Button
                onClick={() => setDialogOpen(false)}
                style={{
                  position: 'absolute',
                  right: '0px',
                  top: '10px',
                  color: '#D2C0C0',
                  backgroundColor: 'transparent',
                  border: 'none',
                  cursor: 'pointer',
                  fontSize: '18px',
                  lineHeight: '1',
                }}
              >
                ×
              </Button>
            </DialogTitle>
            <DialogContent>
              <Typography
                style={{
                  textAlign: 'center',
                  fontWeight: 'bold',
                  fontSize: 16,
                }}
              >
                {`${selectedUser?.name} ${selectedUser?.lastname}`}
              </Typography>
              <Typography style={{ textAlign: 'center' }}>
                {selectedUser?.frontAuth?.includes('manager')
                  ? 'Adlı kullanıcıdan yönetici yetkisini almayı onaylıyor musunuz?'
                  : 'Adlı kullanıcıyı yönetici olarak eklemeyi onaylıyor musunuz?'}
              </Typography>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
              <Button
                disabled={loading}
                onClick={() => setDialogOpen(false)}
                style={{
                  backgroundColor: '#DC423C',
                  color: '#FFFFFF',
                  minWidth: '150px',
                }}
              >
                Kapat
              </Button>
              <Button
                disabled={loading}
                onClick={handleConfirmAction}
                style={{
                  backgroundColor: '#1B5DB2',
                  color: '#FFFFFF',
                  minWidth: '150px',
                }}
              >
                {loading ? (
                  <CircularProgress size={20} style={{ color: 'white' }} />
                ) : (
                  'Onayla'
                )}
              </Button>
            </DialogActions>
          </>
        ) : (
          <>
            <DialogTitle
              style={{ color: '#BBBBBB', marginLeft: -15, marginTop: -15 }}
            >
              Onay Mesajı
              <Button
                onClick={() => {
                  setDialogOpen(false);
                  handleBranchSelect(selectedBranch?.branchID);
                }}
                style={{
                  position: 'absolute',
                  right: '16px',
                  top: '12px',
                  backgroundColor: 'transparent',
                  color: '#D2C0C0',
                  border: 'none',
                  cursor: 'pointer',
                  fontSize: '18px',
                  lineHeight: '1',
                }}
              >
                ×
              </Button>
            </DialogTitle>
            <DialogContent>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginBottom: '10px',
                }}
              >
                <div
                  style={{
                    width: '48px',
                    height: '48px',
                    borderRadius: '50%',
                    backgroundColor: '#22C55E',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <span
                    style={{
                      color: 'white',
                      fontSize: '24px',
                      fontWeight: 'bold',
                    }}
                  >
                    ✓
                  </span>
                </div>
              </div>
              <Typography style={{ textAlign: 'center', fontWeight: 'bold' }}>
                {`${selectedUser?.name} ${selectedUser?.lastname}`}
              </Typography>
              <Typography style={{ textAlign: 'center' }}>
                {selectedUser?.frontAuth?.includes('manager')
                  ? 'Adlı kişinin yönetici yetkisi başarıyla geri alınmıştır.'
                  : 'Adlı kişi başarıyla yönetici olarak eklenmiştir.'}
              </Typography>
            </DialogContent>
          </>
        )}
      </Dialog>
    </>
  );
}
