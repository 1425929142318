import React, { useEffect, useState } from 'react';
import { Box, Modal, Typography, TextField, Button, Stack } from '@mui/material';
import CancelButton from '../shared/CancelButton';
import CompleteButton from '../shared/CompleteButton';
import Services from 'src/services/Services';
import { toast } from 'react-toastify';
import DynamicTable from '../shared/DynamicTable';
import EditButton from '../shared/EditButton';

const AddEditTransportationTool = ({ open, onClose }) => {
    const parsedUser = JSON.parse(localStorage.getItem('user'));
    const token = parsedUser.token;

    const [title, setTitle] = useState('');
    const [toolsList, setToolsList] = useState([]);

    const TOOL_TABLE_HEAD = [
        { id: 'title', label: "Araç Adı", alignRight: false },
    ];

    const handleAddTransportationTool = (title) => {
        Services.AddTransportationTool(token, title)
            .then((response) => {
                if (response.data.status) {
                    toast.success("Yeni araç eklendi.")
                    onClose();
                } else {
                    toast.error("Araç eklenirken bir sorunla karşılaşıldı")
                }
            })
            .catch((error) => {
                console.log(error);
            })
    };

    const handleSubmit = () => {
        if (title.trim()) {
            handleAddTransportationTool(title);
            setTitle('');

        }
    };

    useEffect(() => {
        Services.GetTransportationTools(token)
            .then((response) => {
                if (response.data.status) {
                    setToolsList(response.data.result)
                } else {

                }
            })
            .catch((error) => {
                console.log(error);
            })
    }, []);

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="add-transportation-tool-title"
            aria-describedby="add-transportation-tool-description"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                    minWidth: 300,
                    width: '30%',
                }}
            >
                <Typography id="add-transportation-tool-title" variant="h6" component="h2" mb={2} gutterBottom>
                    Araç Ekleme
                </Typography>
                <Stack spacing={2}>
                    <TextField
                        label="Araç Adı"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        fullWidth
                    />
                </Stack>

                <DynamicTable
                    data={toolsList}
                    headers={TOOL_TABLE_HEAD}
                    initialOrder={"title"}
                    initalOrderDirection={'asc'}
                    isOrdered={true}
                />

                <Stack direction="row" spacing={2} justifyContent="flex-end" mt={3}>
                    <CancelButton onClick={onClose} label="İptal" />
                    <CompleteButton onClick={handleSubmit} disabled={!title} label="Ekle" />
                </Stack>
            </Box>
        </Modal>
    );
};

export default AddEditTransportationTool;
