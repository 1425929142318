import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useEffect, useState } from 'react';
// @mui
import {
  Card,
  Stack,
  Button,
  Typography,
  TextField,
  FormControl,
  useMediaQuery,
  Divider
} from '@mui/material';

// components
// sections
import api from 'src/services/BaseService';
import './muhaberatOutput.css';
import AddEditAvmModal from 'src/components/modals/AddEditAvmModal';
import AddEntityButton from 'src/components/shared/AddEntityButton';
import EditButton from 'src/components/shared/EditButton';
import DynamicTable from 'src/components/shared/DynamicTable';
import CustomFilter from 'src/components/filter/CustomFilter';
import FilterViewButton from 'src/components/shared/FilterViewButton';
import { useLocalization } from 'src/context/LocalizationContext';
import AddEditTransportationTool from 'src/components/modals/AddEditTransportationTool';
// ----------------------------------------------------------------------


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis?.map((el) => el[0]);
}

export default function StoreList() {

  const { language } = useLocalization();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('createdDateTime');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [storeList, setStoreList] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [title, setTitle] = useState('');
  const [city, setCity] = useState('');
  const [county, setCounty] = useState('');
  const [addEditAvmOpen, setAddEditAvmOpen] = useState(false);
  const [isModalEdit, setIsModalEdit] = useState(false);
  const [editData, setEditData] = useState({});
  const [isPrimary, setIsPrimary] = useState('');
  const [mallList, setMallList] = useState([]);
  const isMobile = useMediaQuery('(max-width:768px)');
  const [filterModal, setFilterModal] = useState(false);
  const [addEditTransportationToolOpen, setAddEditTransportationToolOpen] = useState(false);


  const handleModal = () => {
    setFilterModal(true);
  };

  const handleCloseModal = () => {
    setFilterModal(false);
  };
  const [isBackdropOpen, setIsBackdropOpen] = useState(true);

  const STORE_TABLE_HEAD = [
    { id: 'title', label: language.commons.column.mall_plaza, alignRight: false },
    { id: 'city', label: language.commons.city, alignRight: false },
    { id: 'county', label: language.commons.state, alignRight: false },
    {
      id: 'edit', label: '', alignRight: false,
      format: (value, row) => {
        return <EditButton onClick={() => handleEdit(row)} />;
      },
    },
    {
      id: 'quickHandshake', label: '', alignRight: false,
      format: (value, row) => {
        return <Button onClick={() => window.open(`/pre-handshake/${row.id}`, '_blank')}>
          {language.avm_list.titles.pre_handshake}
        </Button>
      }
    },
  ];

  const handleOpenAddEditStoreModal = () => {
    setAddEditAvmOpen(true);
    setIsModalEdit(false);
  };

  const handleCloseAddEditStoreModal = () => {
    setAddEditAvmOpen(false);
    getMalls();
  };

  const handleEdit = (rowData) => {
    setEditData(rowData);
    setAddEditAvmOpen(true);
    setIsModalEdit(true);
  };

  function getAvmList(malls) {
    setStoreList(malls);
    setOriginalData(malls);
  }

  function getMalls() {
    api
      .post('/malls/get-malls', {
        withOffices: true,
      })
      .then((response) => {
        if (response.data) {
          getAvmList(response.data.result);
          setMallList(response.data.result);
          setIsBackdropOpen(false);
        } else {
          // toast.error(response.data.message);
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  useEffect(() => {
    getMalls();
  }, []);

  const getFilteredStoreList = () => {
    let filteredStoreList;
    if (title || city || county || isPrimary) {
      filteredStoreList = originalData.filter((item) => {
        const titleMatch = title
          ? item.title
            .toLowerCase()
            .normalize('NFKD')
            .replace(/[\u0300-\u036F]/g, '')
            .includes(
              title
                .toLowerCase()
                .normalize('NFKD')
                .replace(/[\u0300-\u036F]/g, '')
            )
          : true;
        const cityMatch = city
          ? item.city
            .toLowerCase()
            .normalize('NFKD')
            .replace(/[\u0300-\u036F]/g, '')
            .includes(
              city
                .toLowerCase()
                .normalize('NFKD')
                .replace(/[\u0300-\u036F]/g, '')
            )
          : true;
        const countyMatch = county
          ? item.county
            .toLowerCase()
            .normalize('NFKD')
            .replace(/[\u0300-\u036F]/g, '')
            .includes(
              county
                .toLowerCase()
                .normalize('NFKD')
                .replace(/[\u0300-\u036F]/g, '')
            )
          : true;

        const isPrimaryMatch = isPrimary ? item.isPrimary === isPrimary : true;
        return titleMatch && cityMatch && countyMatch && isPrimaryMatch;
      });
    } else {
      filteredStoreList = originalData;
    }

    setStoreList(filteredStoreList);
  };

  const clearFilters = () => {
    setStoreList(originalData);
    setTitle('');
    setCity('');
    setIsPrimary('');
    setCounty('');
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - storeList.length) : 0;

  const filteredStores = applySortFilter(
    storeList,
    getComparator(order, orderBy),
    filterName
  );

  const isNotFound = !filteredStores.length && !!filterName;

  const handleOpenAddEditTransportationToolModal = () => {
    setAddEditTransportationToolOpen(true);
  };

  const handleCloseAddEditTransportationToolModal = () => {
    setAddEditTransportationToolOpen(false);
  };

  return (
    <>
      <Helmet>
        <title> {language.avm_list.titles.site_title} </title>
      </Helmet>

      <Stack style={{ paddingInline: '25px' }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            {language.avm_list.titles.title}
          </Typography>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <AddEntityButton
              sx={{ marginInline: '8px', marginTop: 1 }}
              label={language.avm_list.titles.add_transportation_tool}
              onClick={handleOpenAddEditTransportationToolModal}
            />
            <AddEntityButton
              sx={{ marginInline: '8px', marginTop: 1 }}
              label={language.avm_list.titles.add_mall}
              onClick={() => handleOpenAddEditStoreModal()}
            >
            </AddEntityButton>
          </Stack>
        </Stack>
        <Stack
          direction="row"
          justifyContent={isMobile ? 'space-between' : 'end'}
          mb={5}
        >

          {isMobile ? <FilterViewButton variant="contained" onClick={handleModal} /> : null}



        </Stack>
        <Card>
          <CustomFilter modalType={'drawer'} filterModal={filterModal} handleCloseModal={handleCloseModal}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-start"
              paddingY={2}
              paddingX={1}
            >
              <Stack
                width={'100%'}
                direction="column"
                alignItems="center"
                justifyContent="flex-start"
                paddingY={2}
                spacing={2}
              >
                <FormControl sx={{ flex: 1, minWidth: '100%' }}>
                  <TextField
                    label={language.commons.labels.mall_name}
                    variant="outlined"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </FormControl>
                <FormControl sx={{ flex: 1, minWidth: '100%' }}>
                  <TextField
                    label={language.commons.city}
                    variant="outlined"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                  />
                </FormControl>

                <FormControl sx={{ flex: 1, minWidth: '100%' }}>
                  <TextField
                    label={language.commons.state}
                    variant="outlined"
                    value={county}
                    onChange={(e) => setCounty(e.target.value)}
                  />
                </FormControl>
                <Divider sx={{ width: '100%' }} />
                <Stack direction="row" justifyContent="center" alignItems="center">
                  <Button
                    sx={{
                      marginRight: '8px',
                      color: 'black',
                      borderColor: 'black',
                      fontWeight: '500',
                      '&:hover': {
                        borderColor: 'black',
                      },
                    }}
                    variant="outlined"
                    onClick={() => clearFilters()}
                  >
                    {language.commons.clear}
                  </Button>
                  <Button
                    sx={{
                      backgroundColor: '#2C7EF8',
                      fontWeight: '500',
                    }}
                    variant="contained"
                    onClick={() => getFilteredStoreList()}
                  >
                    {language.commons.filter}
                  </Button>
                </Stack>
              </Stack>


            </Stack>
          </CustomFilter>

          <DynamicTable
            data={filteredStores}
            headers={STORE_TABLE_HEAD}
            initialOrder={"createdDateTime"}
            initalOrderDirection={'desc'}
            isOrdered={true}
            isMobile={isMobile}
            handleModal={handleModal}
            handleCloseModal={handleCloseModal}
          />
        </Card>
      </Stack>

      {addEditAvmOpen && (
        <AddEditAvmModal
          open={addEditAvmOpen}
          onClose={handleCloseAddEditStoreModal}
          isEdit={isModalEdit}
          editData={editData}
          mallList={mallList}
          isBackdropOpen={isBackdropOpen}
        />
      )}

      {addEditTransportationToolOpen && (
        <AddEditTransportationTool
          open={addEditTransportationToolOpen}
          onClose={handleCloseAddEditTransportationToolModal}
        />
      )}

    </>
  );
}
